export function disableWith(element) {
  element.disabled = true
  element.classList.add('button-disabled')
  element.dataset.activeLabel = element.textContent
  element.textContent = 'Syncing...'
}

export function revertDisableWith(element) {
  element.disabled = false
  element.textContent = element.dataset.activeLabel
  element.classList.remove('button-disabled')
}
